import styled, { css } from "styled-components";

import { Icon } from "components/ui/icon";

import { ButtonSizes, ButtonTypes } from "./Button";

import { media } from "constants/media";

const linkStyling = css`
    background-color: transparent;
    font-size: 15px;
    line-height: 24px;
    padding: 0;
    position: relative;

    ::after {
        border-radius: 50em;
        content: "";
        height: calc(100% + 4px);
        left: -10px;
        position: absolute;
        top: -2px;
        transform: scaleX(0);
        transform-origin: center;
        transition: all var(--default-transition-duration) ease;
        width: calc(100% + 20px);
        z-index: -1;
    }

    svg {
        transition: all var(--default-transition-duration) ease;
    }

    :hover {
        color: var(--color-grey-60);

        svg {
            fill: var(--color-grey-60);
            stroke: var(--color-grey-60);
            transform: translateX(4px);
        }
    }

    :focus {
        svg {
            transform: translateX(4px);
        }

        ::after {
            background: rgba(0, 176, 191, 0.1);
            transform: scaleX(1);

            svg {
                transform: translateX(4px);
            }
        }
    }
`;

function determineSizeStyling(size: ButtonSizes, type: ButtonTypes) {
    if (type.includes("link")) {
        switch (size) {
            case "small":
                return css`
                    font-size: 14px;
                    line-height: 24px;
                `;
            case "large":
            default:
                return css`
                    font-size: 15px;
                    line-height: 24px;
                `;
        }
    }

    switch (size) {
        case "small":
            return css`
                font-size: 14px;
                line-height: 24px;
                min-height: 48px;
                padding: 12px 16px;
            `;
        case "round":
            return css`
                border-radius: 50%;
                font-size: 14px;
                line-height: 22px;
                padding: 10px;
            `;
        case "large":
        default:
            return css`
                font-size: 15px;
                line-height: 24px;
                min-height: 56px;
                padding: 16px 24px;
            `;
    }
}

function determineTypeStyling(type: ButtonTypes) {
    switch (type) {
        case "secondary":
            return css`
                background-color: var(--color-black);
                color: var(--color-white);

                &:hover {
                    background-color: var(--color-grey-60);
                }

                &:focus {
                    box-shadow: 0 0 8px var(--color-orange-50);
                }
            `;
        case "tertiary":
            return css`
                background-color: var(--color-white);
                color: var(--color-black);

                &:focus {
                    box-shadow: 0 0 8px var(--color-orange-50);
                }
            `;
        case "quaternary":
            return css`
                background-color: var(--color-white);
                color: var(--color-orange-50);

                &:focus {
                    box-shadow: 0 0 8px var(--color-orange-50);
                }
            `;
        case "quinary":
            return css`
                background-color: var(--color-white);
                color: var(--color-black);
            `;
        case "link":
            return css`
                color: var(--color-orange-60);

                ::before {
                    background-color: var(--color-orange-60);
                }

                /* stylelint-disable-next-line order/order */
                ${linkStyling}
            `;
        case "link-light":
            return css`
                color: var(--color-white);

                ::before {
                    background-color: var(--color-white);
                }

                /* stylelint-disable-next-line order/order */
                ${linkStyling}
            `;
        case "link-grey":
            return css`
                color: var(--color-grey-55);

                ::before {
                    background-color: var(--color-white);
                }

                /* stylelint-disable-next-line order/order */
                ${linkStyling}
            `;
        case "link-dark":
            return css`
                color: var(--color-black);

                ::before {
                    background-color: var(--color-black);
                }

                /* stylelint-disable-next-line order/order */
                ${linkStyling}
            `;
        case "ghost":
            return css`
                background-color: transparent;
                border: 1px solid var(--color-orange-60);
                color: var(--color-orange-60);

                &:focus {
                    box-shadow: 0 0 8px var(--color-orange-50);
                }
            `;
        case "ghost-dark":
            return css`
                background-color: transparent;
                border: 1px solid var(--color-black);
                color: var(--color-black);

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        border-color: var(--color-orange-50);
                        color: var(--color-orange-50);
                    }
                }

                &:focus {
                    box-shadow: 0 0 8px var(--color-orange-50);
                }
            `;
        case "ghost-light":
            return css`
                background-color: transparent;
                border: 1px solid var(--color-white);
                color: var(--color-white);

                &:focus {
                    box-shadow: 0 0 8px var(--color-orange-50);
                }
            `;
        case "primary":
        default:
            return css`
                background-color: var(--color-orange-60);
                color: var(--color-white);

                &:hover,
                &:focus {
                    background-color: var(--color-orange-50);
                }

                &:focus {
                    box-shadow: 0 0 8px var(--color-orange-50);
                }

                &:active {
                    background-color: var(--color-orange-45);
                }
            `;
    }
}

function determineIconStyling(type: ButtonTypes) {
    switch (type) {
        case "tertiary":
        case "quinary":
        case "link-dark":
        case "ghost-dark":
            return css`
                fill: var(--color-black);
                stroke: var(--color-black);
            `;
        case "ghost":
        case "link":
            return css`
                fill: var(--color-orange-50);
                stroke: var(--color-orange-50);
            `;
        case "primary":
        case "secondary":
        case "link-light":
        case "ghost-light":
        default:
            return css`
                fill: var(--color-white);
                stroke: var(--color-white);
            `;
    }
}

const disabledStyling = css`
    opacity: 0.3;
    pointer-events: none;
`;

export const Content = styled.button<{
    disabled: boolean;
    reversedOrder: boolean;
    buttonType: ButtonTypes;
    size: ButtonSizes;
}>`
    align-items: center;
    border: 0;
    border-radius: 2em;
    cursor: pointer;
    display: inline-flex;
    font-weight: bold;
    justify-content: center;
    text-decoration: none;
    transition: all var(--quick-transition-duration) ease-in-out;

    /* stylelint-disable */
    ${({ size, buttonType }) => determineSizeStyling(size, buttonType)}
    ${({ buttonType }) => determineTypeStyling(buttonType)};
    ${({ reversedOrder }) =>
        reversedOrder &&
        css`
            flex-direction: row-reverse;
        `}
    ${({ disabled }) => (disabled ? disabledStyling : null)};
    /* stylelint-enable */
`;

export const StyledIconWrapper = styled.div<{
    hasChildren?: boolean;
    reversedOrder?: boolean;
}>`
    align-items: center;
    display: flex;
    height: 100%;

    ${({ hasChildren, reversedOrder }) =>
        hasChildren &&
        css`
            ${reversedOrder ? "margin: 0 -5px 0 5px" : "margin: 0 5px 0 -5px"};

            ${media.mdUp} {
                ${reversedOrder ? "margin-left: 8px" : "margin-right: 8px"};
            }
        `}
`;

export const StyledIcon = styled(Icon)<{ buttonType: ButtonTypes }>`
    height: 100%;

    ${({ buttonType }) => determineIconStyling(buttonType)}
`;
