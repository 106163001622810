import styled from "styled-components";

import { Container, FramerMotionWrapper } from "components/ui";

import { media } from "constants/media";

export const Wrapper = styled.section`
    background-color: var(--color-orange-50);
    padding: 0 0 320px;
    position: relative;

    ${media.mdUp} {
        background-color: transparent;
        padding: 0 0 170px;
    }

    ${media.lgUp} {
        min-height: 947px;
        padding: 0 0 210px;
    }
`;

export const StyledContainer = styled(Container)`
    padding: 68px 24px 0;
    position: relative;

    ${media.mdUp} {
        padding: 96px 24px 0;
    }

    ${media.lgUp} {
        padding: 210px 24px 0;
    }
`;

export const Content = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    grid-column: span 4;
    text-align: center;
    z-index: var(--z-index-homepage-section);

    ${media.mdUp} {
        align-items: flex-start;
        text-align: left;
    }

    ${media.lgUp} {
        grid-column: span 5;
    }
`;

export const Description = styled.p`
    font-family: var(--font-secondary);
    font-size: var(--type-heading-5-font-size);
    line-height: var(--type-heading-5-line-height);
`;

export const Links = styled.ul`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    list-style: none;

    ${media.mdUp} {
        flex-direction: row;
    }
`;

export const LinksItem = styled.li`
    align-items: stretch;
    width: 100%;

    a {
        display: flex;
        justify-content: center;
    }

    ${media.mdUp} {
        align-items: flex-start;
        width: auto;

        a {
            display: inline-flex;
            justify-content: flex-start;
        }
    }
`;

export const ImageWrapper = styled.div`
    aspect-ratio: 1 / 1;
    position: absolute;
    right: 50%;
    top: calc(100% + 60px);
    transform: translateX(80%);
    width: 262px;
    z-index: 1;

    ${media.mdUp} {
        bottom: auto;
        right: -90px;
        top: 0;
        transform: none;
        width: 465px;
    }

    ${media.lgUp} {
        right: -350px;
        top: 0;
        width: 947px;
    }

    ${media.xlUp} {
        right: -235px;
    }
`;

export const StyledFramerMotionWrapper = styled(FramerMotionWrapper)`
    aspect-ratio: 1 / 1;
    position: relative;
    width: 100%;
`;

export const Road = styled.div<{ inView: boolean }>`
    background-image: url("/images/homepage-bg-road-mobile.svg");
    background-position: top center;
    background-repeat: no-repeat;
    height: 2299px;
    left: calc(50% - 76px);
    opacity: ${({ inView }) => (inView ? 1 : 0)};
    position: absolute;
    top: calc(100% - 67px);
    transform: translateX(-50%);
    transform-origin: top left;
    transition: opacity 0.5s ease;
    transition-delay: 0.25s;
    width: 1155px;
    z-index: var(--z-index-homepage-bg);

    ${media.mdUp} {
        background-image: url("/images/homepage-bg-road-tablet.svg");
        height: 1820px;
        left: calc(50% + -150px);
        top: calc(100% - 234px);
        width: 1536px;
    }

    ${media.lgUp} {
        background-image: url("/images/homepage-bg-road-desktop.svg");
        height: 3566px;
        left: calc(50% + 120px);
        top: calc(100% - 258px);
        width: 2635px;
    }
`;
