import { MoreInformationProps } from "storyblok/components";
import Image from "next/legacy/image";
import Link from "next/link";

import {
    ARROW_RIGHT,
    AnimationStyle,
    FramerMotionWrapper,
    Icon
} from "components/ui";

import {
    IconBackground,
    ImageWrapper,
    LinkWrapper,
    Links,
    StyledContainer,
    StyledHeading,
    StyledScrollContainer,
    Title,
    Wrapper
} from "./MoreInformation.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function MoreInformation({ blok }: MoreInformationProps): JSX.Element {
    const { title, firstRowLinks, secondRowLinks } = blok;
    const { isTabletViewOrLarger } = useMediaQueryContext();

    return (
        <StyledContainer>
            <StyledHeading>{title}</StyledHeading>
            {!isTabletViewOrLarger ? (
                <Links>
                    {firstRowLinks.map((link, index) => (
                        <FramerMotionWrapper
                            key={index}
                            playWhenInView
                            animationType={AnimationStyle.FadeInFromBottom}
                            duration={0.15}
                            delay={index * 0.15}
                        >
                            <LinkWrapper>
                                <ImageWrapper>
                                    <Image
                                        src={link.avatar.filename}
                                        objectFit="cover"
                                        layout="fill"
                                    />
                                </ImageWrapper>
                                <Link legacyBehavior href={link.url} passHref>
                                    <Title>{link.title}</Title>
                                </Link>
                                <Icon
                                    icon={ARROW_RIGHT}
                                    size={24}
                                    color="var(--color-orange-60)"
                                />
                            </LinkWrapper>
                        </FramerMotionWrapper>
                    ))}
                    {secondRowLinks &&
                        secondRowLinks.map((link, index) => (
                            <FramerMotionWrapper
                                key={index}
                                playWhenInView
                                animationType={AnimationStyle.FadeInFromBottom}
                                duration={0.15}
                                delay={index * 0.15 + 0.75}
                            >
                                <LinkWrapper>
                                    <ImageWrapper>
                                        <Image
                                            src={link.avatar.filename}
                                            objectFit="cover"
                                            layout="fill"
                                        />
                                    </ImageWrapper>
                                    <Link
                                        legacyBehavior
                                        href={link.url}
                                        passHref
                                    >
                                        <Title>{link.title}</Title>
                                    </Link>
                                    <Icon
                                        icon={ARROW_RIGHT}
                                        size={24}
                                        color="var(--color-orange-60)"
                                    />
                                </LinkWrapper>
                            </FramerMotionWrapper>
                        ))}
                </Links>
            ) : (
                <>
                    <Wrapper>
                        <StyledScrollContainer horizontal vertical={false}>
                            {firstRowLinks.map((link, index) => (
                                <FramerMotionWrapper
                                    key={index}
                                    playWhenInView
                                    animationType={AnimationStyle.FadeIn}
                                    duration={0.15}
                                    delay={index * 0.15}
                                >
                                    <LinkWrapper>
                                        <ImageWrapper>
                                            <Image
                                                src={link.avatar.filename}
                                                objectFit="cover"
                                                layout="fill"
                                            />
                                        </ImageWrapper>
                                        <Title>{link.title}</Title>
                                        <Link
                                            legacyBehavior
                                            href={link.url}
                                            passHref
                                        >
                                            <IconBackground>
                                                <Icon
                                                    icon={ARROW_RIGHT}
                                                    size={24}
                                                    color="var(--color-black)"
                                                />
                                            </IconBackground>
                                        </Link>
                                    </LinkWrapper>
                                </FramerMotionWrapper>
                            ))}
                        </StyledScrollContainer>
                    </Wrapper>

                    {secondRowLinks && secondRowLinks.length !== 0 && (
                        <Wrapper>
                            <StyledScrollContainer horizontal vertical={false}>
                                {secondRowLinks.map((link, index) => (
                                    <FramerMotionWrapper
                                        key={index}
                                        playWhenInView
                                        animationType={AnimationStyle.FadeIn}
                                        duration={0.15}
                                        delay={index * 0.15}
                                    >
                                        <LinkWrapper>
                                            <ImageWrapper>
                                                <Image
                                                    src={link.avatar.filename}
                                                    objectFit="cover"
                                                    layout="fill"
                                                />
                                            </ImageWrapper>
                                            <Title>{link.title}</Title>
                                            <Link
                                                legacyBehavior
                                                href={link.url}
                                                passHref
                                            >
                                                <IconBackground>
                                                    <Icon
                                                        icon={ARROW_RIGHT}
                                                        size={24}
                                                        color="var(--color-black)"
                                                    />
                                                </IconBackground>
                                            </Link>
                                        </LinkWrapper>
                                    </FramerMotionWrapper>
                                ))}
                            </StyledScrollContainer>
                        </Wrapper>
                    )}
                </>
            )}
        </StyledContainer>
    );
}
