import { ChangeEvent, ForwardedRef, ReactNode, forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Icon } from "components/ui";

import {
    IconWrapper,
    InputWrapper,
    LabelWrapper,
    StyledErrorMessage,
    StyledInput,
    StyledLabel
} from "./Input.styled";

import { AutocompleteOptions, InputType } from "types/input";

export interface InputProps {
    /** Inform password managers and browsers about the input field how to autofill this field */
    autoComplete?: AutocompleteOptions;
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Any icon (`<path /> or <g />`) imported from "IconSet.tsx". */
    icon?: ReactNode;
    /** Input disabled or not. */
    disabled?: boolean;
    /** Style input with error styles, without the message */
    hasError?: boolean;
    /** Error message, generated by react-hook-forms */
    errorMessage?: FieldError;
    /** Specify the HTML input type attribute */
    inputType?: InputType;
    /** Label for the input. */
    label: string;
    /** name used for tracking the value of the input, used by react-hook-forms. */
    name: string;
    /** Disables wrapping for the label */
    noWrap?: boolean;
    /** Placeholder value shown when no value has been inputted to the field */
    placeholder?: string;
    /** Max length for the input field */
    maxLength?: number;
    value?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onIconClick?: () => void;
}

export const Input = forwardRef(function Input(
    {
        autoComplete = "off",
        className,
        disabled = false,
        hasError = false,
        errorMessage,
        label,
        name,
        noWrap,
        inputType = "text",
        placeholder = "",
        maxLength,
        icon,
        value,
        onIconClick = () => null,
        onChange,
        ...otherProps
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
) {
    return (
        <StyledLabel className={className} disabled={disabled}>
            <LabelWrapper noWrap={noWrap}>{label}</LabelWrapper>
            <InputWrapper>
                <StyledInput
                    autoComplete={autoComplete}
                    data-testid="styled-input"
                    hasError={Boolean(errorMessage || hasError)}
                    hasIcon={Boolean(icon)}
                    disabled={disabled}
                    placeholder={placeholder}
                    type={inputType}
                    name={name}
                    ref={ref}
                    maxLength={maxLength}
                    value={value}
                    onChange={onChange}
                    {...otherProps}
                />
                {icon && (
                    <IconWrapper onClick={() => onIconClick()}>
                        <Icon icon={icon} size={24} />
                    </IconWrapper>
                )}
            </InputWrapper>
            {errorMessage && (
                <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
            )}
        </StyledLabel>
    );
});
