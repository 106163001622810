import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";

import { Container } from "components/ui";

import { media } from "constants/media";

export const IconBackground = styled.div`
    align-items: center;
    background: var(--color-white);
    border-radius: 50%;
    bottom: -50%;
    cursor: pointer;
    display: flex;
    height: 56px;
    justify-content: center;
    position: absolute;
    transition-duration: var(--default-transition-duration);
    transition-property: all;
    transition-timing-function: ease;
    width: 56px;
`;

export const StyledContainer = styled(Container)`
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 40px;

    ${media.mdUp} {
        margin: 0 auto;
        max-width: 1200px;
        padding: 120px 0;
        width: 100%;
    }
`;

export const Links = styled.ul`
    list-style: none;

    ${media.mdUp} {
        flex-direction: row;
    }
`;

export const StyledHeading = styled.h3`
    margin-bottom: 24px;

    ${media.mdUp} {
        margin-bottom: 40px;
        text-align: center;
    }
`;

export const LinkWrapper = styled.li`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 56px;
    justify-content: space-between;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;

    svg {
        min-height: 24px;
        min-width: 24px;
    }

    ${media.mdUp} {
        //stylelint-disable
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(29, 29, 29, 0.6) 100%
        );
        //stylelint-enable
        border-radius: 400px;
        flex-direction: column;
        height: 448px;
        margin: 0 8px;
        width: 304px;

        :hover {
            ${IconBackground} {
                bottom: 64px;
            }

            img {
                transform: scale(1.05);
                transition-duration: var(--default-transition-duration);
                transition-property: all;
                transition-timing-function: ease;
            }
        }
    }
`;

export const ImageWrapper = styled.div`
    height: 48px;
    margin-right: 12px;
    min-height: 48px;
    min-width: 48px;
    position: relative;
    width: 48px;

    //stylelint-disable-next-line
    img {
        border-radius: 100%;
    }

    ${media.mdUp} {
        height: 448px;
        margin: 0;
        min-width: 304px;
        mix-blend-mode: multiply;
        width: 304px;

        //stylelint-disable-next-line
        img {
            border-radius: 400px;
            transition-duration: var(--default-transition-duration);
            transition-property: all;
            transition-timing-function: ease;
        }
    }
`;

export const Title = styled.a`
    align-items: center;
    display: flex;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    line-height: 24px;
    text-decoration: none;
    width: 100%;

    ${media.mdUp} {
        bottom: 33%;
        color: var(--color-white);
        font-size: 26px;
        justify-content: center;
        line-height: 40px;
        margin: auto;
        max-width: 272px;
        position: absolute;
        text-align: center;
    }
`;

export const Wrapper = styled.div`
    ${media.mdUp} {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        width: 100%;
    }
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const StyledScrollContainer = styled(ScrollContainer)`
    cursor: grab;
    display: flex;
    height: 448px;
`;
