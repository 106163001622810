import { Children, ForwardedRef, forwardRef } from "react";

import { Content, StyledIcon, StyledIconWrapper } from "./Button.styled";

export type ButtonSizes = "small" | "large" | "round";

export type ButtonTypes =
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quinary"
    | "link"
    | "link-light"
    | "link-dark"
    | "link-grey"
    | "ghost"
    | "ghost-dark"
    | "ghost-light";

export type ButtonProps = {
    size?: ButtonSizes;
    buttonType: ButtonTypes;
    disabled?: boolean;
    onClick?: () => void;
    href?: string;
    children?: React.ReactNode;
    icon?: React.ReactNode;
    reversedOrder?: boolean;
    className?: string;
    role?: string;
};

// eslint-disable-next-line react/display-name
export const Button = forwardRef(
    (
        {
            className,
            children,
            href,
            disabled = false,
            onClick,
            icon,
            buttonType = "primary",
            reversedOrder = false,
            size = "large",
            role,
            ...otherProps
        }: ButtonProps,
        ref: ForwardedRef<HTMLButtonElement>
    ): JSX.Element => {
        return (
            <Content
                as={href ? "a" : "button"}
                ref={ref}
                href={href}
                onClick={onClick}
                buttonType={buttonType}
                disabled={disabled || false}
                reversedOrder={reversedOrder}
                size={size}
                className={className}
                role={role}
                {...otherProps}
            >
                {icon && (
                    <StyledIconWrapper
                        hasChildren={!!Children.count(children)}
                        reversedOrder={reversedOrder}
                    >
                        <StyledIcon
                            icon={icon}
                            size={size === "small" ? 20 : 24}
                            buttonType={buttonType}
                        />
                    </StyledIconWrapper>
                )}
                {children}
            </Content>
        );
    }
);
