import styled, { css } from "styled-components";

import {
    Container as BaseContainer,
    Subtitle as BaseSubtitle
} from "components/ui";

import { media } from "constants/media";

export const Background = styled.div<{ background: boolean }>`
    ${({ background }) =>
        background &&
        css`
            background-image: url("/images/professional-bg-mobile.webp");
            background-repeat: no-repeat;
            background-size: cover;
            color: white;
            position: relative;

            ${media.xlUp} {
                background-image: url("/images/stories-bg.webp");
            }
        `}
`;

export const Container = styled(BaseContainer)`
    flex-direction: column;
    gap: 24px;
    margin-top: 16px;

    ${media.mdUp} {
        flex-direction: column-reverse;
        gap: 48px;
        min-height: 348px;
        padding-right: 0;
    }

    ${media.lgUp} {
        flex-direction: row;
        overflow: visible;
    }
`;

export const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
    hyphens: auto;
    overflow-wrap: break-word;
    padding: 24px 0;

    p {
        font-family: var(--font-secondary);
        font-size: var(--type-paragraph-large-font-size);
        line-height: var(--type-paragraph-large-line-height);
    }

    ${media.mdUp} {
        gap: 24px;
        margin: auto 0;
    }

    ${media.lgUp} {
        flex: 1 0 576px;
        padding: 80px 0;
    }
`;

export const Title = styled.h1`
    font-size: 32px;

    ${media.mdUp} {
        font-size: 40px;
        line-height: 52px;
    }
`;

export const Subtitle = styled(BaseSubtitle)`
    font-weight: var(--font-weight-extra-bold);
    text-transform: uppercase;
`;

export const MoreInfoContainer = styled.div`
    color: var(--color-grey-60);
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.lgUp} {
        flex-direction: row;
        gap: 0;

        a {
            justify-content: flex-start;
        }
    }
`;

export const Links = styled.div`
    display: grid;
    flex: 1;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
`;
