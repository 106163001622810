import styled from "styled-components";

import { media } from "constants/media";

function resolveColor(isActive: boolean): string {
    return isActive ? "var(--color-white)" : "var(--color-orange-60)";
}

export const MenuForeGround = styled.div<{
    isMenuActive: boolean;
    isSubMenuActive: boolean;
    height: number;
    stickyBarActive: boolean;
}>`
    background-color: ${({ isSubMenuActive }) => resolveColor(isSubMenuActive)};
    height: ${({ height }) => height}px;
    max-height: calc(100vh - 80px);
    opacity: ${({ isMenuActive }) => (isMenuActive ? 1 : 0)};
    position: fixed;
    top: ${({ stickyBarActive }) => (stickyBarActive ? "66px" : "106px")};
    transform: translateY(${({ isMenuActive }) => (isMenuActive ? 0 : "20px")});
    transform-origin: top center;
    transition-duration: var(--quick-transition-duration);
    transition-property: opacity, transform, height;
    transition-timing-function: ease;
    visibility: ${({ isMenuActive }) => (isMenuActive ? "visible" : "hidden")};
    width: ${({ isMenuActive }) => (isMenuActive ? "100vw" : "0vw")};
    z-index: var(--z-index-menu-foreground);

    ${media.lgUp} {
        background-color: var(--color-white);
        bottom: auto;
        opacity: 1;
        position: absolute;
        top: 100%;
        transform: scaleY(${({ isMenuActive }) => (isMenuActive ? 1 : 0)});
        transition-property: transform, height;
        width: auto;
    }
`;

export const MenuBackground = styled.div<{
    isMenuActive: boolean;
}>`
    background-color: rgba(0, 0, 0, 0.25);
    height: 100vh;
    left: 0;
    opacity: ${({ isMenuActive }) => (isMenuActive ? 1 : 0)};
    position: fixed;
    top: 0;
    transition: all var(--default-transition-duration) ease;
    visibility: ${({ isMenuActive }) => (isMenuActive ? "visible" : "hidden")};
    width: 100vw;
    z-index: var(--z-index-menu-background);

    ${media.lgUp} {
        height: calc(100vh - 32px);
        top: 125px;
    }
`;
