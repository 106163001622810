import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

import { Button, ButtonTypes } from "components/ui/button";
import { NavItem } from "components/shared/header/navigation/item/NavItem";

import { ListItem, NavList, StyledNav } from "./SecondaryNavigation.styled";
import { SavedJobs } from "./savedJobs";
import { SecondarySubMenu } from "./submenu";

import { useMediaQueryContext } from "helpers/hooks";
import { useMenuContext } from "helpers/hooks/useMenuContext";

export function SecondaryNavigation(): JSX.Element {
    const { isDesktopView, isXlDesktopView, isMobileView, isTabletView } =
        useMediaQueryContext();
    const { isSubMenuActive } = useMenuContext();
    const { t } = useTranslation();
    const buttonType: ButtonTypes = isDesktopView ? "primary" : "quaternary";

    return (
        <StyledNav
            showMenu={!isSubMenuActive}
            role="navigation"
            aria-label="Freelancer"
        >
            <NavList>
                {isDesktopView && (
                    <ListItem>
                        <NavItem
                            id="freelancer"
                            href="/zzper"
                            title={t("common:freelance")}
                            type="secondary"
                        >
                            <SecondarySubMenu />
                        </NavItem>
                    </ListItem>
                )}
                <ListItem>
                    <Link
                        legacyBehavior
                        href="/zorgprofessionals/vacatures"
                        passHref
                        prefetch={false}
                    >
                        <Button
                            buttonType={buttonType}
                            size={isXlDesktopView ? "large" : "small"}
                        >
                            {t("common:jobs")}
                        </Button>
                    </Link>
                </ListItem>
                {(isMobileView || isTabletView) && <SavedJobs />}
                {isXlDesktopView && (
                    <ListItem>
                        <SavedJobs />
                    </ListItem>
                )}
            </NavList>
        </StyledNav>
    );
}
