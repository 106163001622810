import { Button, HomepageHeroBlok } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";
import { useInView } from "react-intersection-observer";
import Image from "next/legacy/image";

import { Heading } from "components/ui";
import { StoryblokButton } from "components/shared/storyblok";

import {
    Content,
    Description,
    ImageWrapper,
    Links,
    LinksItem,
    Road,
    StyledContainer,
    Wrapper
} from "./HomepageHero.styled";
import { Pointer } from "./pointer";

import { useMediaQueryContext } from "helpers/hooks";

export function HomepageHero({ blok }: HomepageHeroBlok): JSX.Element {
    const { ref, inView } = useInView({
        initialInView: false,
        triggerOnce: true
    });
    const { isMobileView } = useMediaQueryContext();
    const { title, description, buttons } = blok;

    const Buttons = (
        <Links>
            {buttons.map((button: Button) => {
                return (
                    button.component === "Button" && (
                        <LinksItem key={button._uid}>
                            <StoryblokButton {...button} />
                        </LinksItem>
                    )
                );
            })}
        </Links>
    );

    return (
        <Wrapper ref={ref} {...storyblokEditable(blok)}>
            <StyledContainer type="grid">
                <Pointer />
                <Content>
                    <Heading headingLevel={1}>{title}</Heading>
                    <Description>{description}</Description>
                    {Buttons}
                </Content>
                <ImageWrapper>
                    {!isMobileView ? (
                        <Image
                            src="/images/homepage-hero.webp"
                            priority
                            loading="eager"
                            alt="Homepage hero banner"
                            layout="fill"
                        />
                    ) : (
                        <Image
                            src="/images/homepage-hero-mobile.webp"
                            priority
                            loading="eager"
                            alt="Homepage hero banner"
                            layout="fill"
                        />
                    )}
                </ImageWrapper>
            </StyledContainer>
            <Road inView={inView} />
        </Wrapper>
    );
}
