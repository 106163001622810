import styled from "styled-components";

export interface SubtitleProps {
    /** WARNING: DO NOT pass className manually - This has to be set in this way to fix a caveat with typescript and styled components. */
    className?: string;
    /** Pass any React.Node to render inside this component */
    children: React.ReactNode;
}

const StyledSubtitle = styled.span`
    display: inline-block;
    font-size: var(--type-subtitle-font-size);
    line-height: var(--type-subtitle-line-height);
`;

export function Subtitle({ className, children }: SubtitleProps): JSX.Element {
    return <StyledSubtitle className={className}>{children}</StyledSubtitle>;
}
