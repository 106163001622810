import styled from "styled-components";

import { media } from "constants/media";

export const Wrapper = styled.div`
    background: url("/images/home-hero-bg-shape.svg") no-repeat top center;
    background-size: auto 1150px;
    min-height: 3566px;
    overflow: hidden;
    position: relative;
    width: 100vw;

    ${media.mdUp} {
        background-size: auto 950px;
    }

    ${media.lgUp} {
        background-size: auto;
    }
`;

export const BackgroundGradient = styled.div`
    background: var(--color-orange-40);
    border-radius: 100%;
    filter: blur(500px);
    height: 692px;
    left: calc(50% - 440px);
    position: absolute;
    top: 550px;
    width: 898px;
    z-index: 0;

    ${media.mdUp} {
        height: 856px;
        left: calc(50% - 860px);
        top: 144px;
        width: 1024px;
    }

    ${media.lgUp} {
        height: 1096px;
        left: calc(50% - 1290px);
        top: 512px;
        width: 1312px;
    }
`;
