import { OrganisationsHeroProps } from "storyblok/components";
import { storyblokEditable } from "@storyblok/react";

import { Button, Icons } from "components/ui";
import { HeroPillImage } from "components/shared/storyblok/HeroPillImage";

import {
    Background,
    Container,
    Content,
    Links,
    MoreInfoContainer,
    Subtitle,
    Title
} from "./OrganisationsHero.styled";

import { useMediaQueryContext } from "helpers/hooks";

export function OrganisationsHero({
    blok
}: OrganisationsHeroProps): JSX.Element {
    const { isMobileView } = useMediaQueryContext();
    const { title, subtitle, description, image, linksBlok, background } = blok;
    const imageBlok = image[0];

    return (
        <Background background={background}>
            <Container {...storyblokEditable(blok)}>
                {isMobileView && imageBlok && (
                    <HeroPillImage
                        image={imageBlok?.image}
                        size={imageBlok?.size}
                    />
                )}
                <Content>
                    {title && <Title>{title}</Title>}
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                    {description && <p>{description}</p>}
                    {linksBlok && linksBlok?.[0] && (
                        <MoreInfoContainer>
                            <Subtitle>{linksBlok?.[0].title}</Subtitle>

                            <Links>
                                {linksBlok?.[0].links.map(linkItem => (
                                    <Button
                                        size={linkItem?.size}
                                        icon={Icons[linkItem?.icon]}
                                        reversedOrder={linkItem?.reversedOrder}
                                        key={linkItem?.title}
                                        href={linkItem?.href}
                                        buttonType={linkItem?.buttonType}
                                    >
                                        {linkItem?.title}
                                    </Button>
                                ))}
                            </Links>
                        </MoreInfoContainer>
                    )}
                </Content>
                {!isMobileView && imageBlok && (
                    <HeroPillImage
                        image={imageBlok?.image}
                        size={imageBlok?.size}
                    />
                )}
            </Container>
        </Background>
    );
}

export default OrganisationsHero;
